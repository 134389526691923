import React from 'react'
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import logo from "../Media/logo.png";
import wedding_mansion_logo from "../Media/wedding_mansion_logo.jpg";

const WebsiteFooter = () => {
    const OpenInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <section className="section_footer ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <img src={logo} alt="footer logo" className="footer_logo d-block mx-auto" />
                            <h5 className="text-white text-center">WEDDING EXPO BY DHRUVA</h5>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    Behind Bangalore Main Palace, Vasanth Nagar, <br />Palace Grounds, Banagalore - 560003.
                                </div>
                            </div>

                            <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://www.instagram.com/dhruvawmco/?hl=en")}>
                                <BsIcons.BsInstagram />
                            </button>

                        </div>
                        <div className="col-md-4 text-center">
                            <img src={wedding_mansion_logo} alt="footer wedding_mansion_logo" className="footer_logo d-block mx-auto" />
                            <h5 className="text-white text-center">WEDDING MANSION & CO</h5>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    No.29, Concord Homes, Kudlu Entrance, <br />Banaglore - 5600068.
                                </div>
                            </div>
                            {/* <p className="text-white text-center">No. 29, Concord Homes, Kudlu Entrance, Banaglore - 5600068.</p> */}

                            <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2 " onClick={() => OpenInNewTab("https://www.instagram.com/dhruvawmco/?hl=en")}>
                                <BsIcons.BsInstagram />
                            </button>

                        </div>
                        <div className="col-md-4">
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <div className="div_footer_header">
                                        <h4 className="text-white h_tag_footer_header"><b>REACH TO US</b></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <p className="text-white " onClick={() => OpenInNewTab("mailto:info@weddingsbydhruva.com")}><MdIcons.MdEmail />&nbsp;<span className="p_contact_link">info@weddingsbydhruva.com</span></p>
                                    <p className="text-white " onClick={() => OpenInNewTab("mailto:info@weddingsbydhruva.com")}><MdIcons.MdWeb />&nbsp;<span className="p_contact_link">http://www.weddingsbydhruva.com/</span></p>
                                    <p className="text-white " onClick={() => OpenInNewTab("tel:9844405540")}><MdIcons.MdPhoneAndroid />&nbsp;<span className="p_contact_link">9844405540</span></p>
                                    <p className="text-white " onClick={() => OpenInNewTab("tel:08023315540")}><MdIcons.MdCall />&nbsp;<span className="p_contact_link">08023315540</span></p>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-md-3">
                            <div className="text-center pt-3">
                                <h4 className="text-white text-center "><b>FOLLOW US</b></h4>
                            </div>

                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <ul>
                                        <li className="m-1">
                                            Dhruva
                                            <div className="div_social_icons">
                                                <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://instagram.com/dhruvawmco?utm_medium=copy_link")}>
                                                    <BsIcons.BsInstagram />
                                                </button>
                                            </div>
                                        </li>
                                        <li className="m-1">
                                            Wedding entertainment by Dhruva
                                            <div className="div_social_icons">
                                                <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://instagram.com/musicmansionbangalore_official?utm_medium=copy_link")}>
                                                    <BsIcons.BsInstagram />
                                                </button>
                                            </div>
                                        </li>
                                        <li className="m-1">
                                            May Dhruva
                                            <div className="div_social_icons">
                                                <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://www.instagram.com/dhruvawmco/?hl=en")}>
                                                    <BsIcons.BsInstagram />
                                                </button>
                                            </div>
                                        </li>
                                        <li className="m-1">
                                            Wedding solution by Dhruva
                                            <div className="div_social_icons">
                                                <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://www.instagram.com/dhruvawmco/?hl=en")}>
                                                    <BsIcons.BsInstagram />
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div> */}

                    </div>

                    <div className="w-100 mx-auto border border-top-light" />
                    <div className="" style={{ textAlign: "center" }}>
                        <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                            <h4 className="text-white h_tag_footer_header pt-2"><b>FOLLOW US </b></h4>
                        </div>
                    </div>

                    <div className="row p-3">
                        <div className="col-3 text-center">
                            <span className="text-white">Dhruva</span>
                            <div className="div_social_icons">
                                <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://instagram.com/dhruvawmco?utm_medium=copy_link")}>
                                    <BsIcons.BsInstagram />
                                </button>
                            </div>
                        </div>
                        <div className="col-3 text-center">
                            <span className="text-white">Wedding entertainment by Dhruva</span>
                            <div className="div_social_icons">
                                <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://instagram.com/musicmansionbangalore_official?utm_medium=copy_link")}>
                                    <BsIcons.BsInstagram />
                                </button>
                            </div>
                        </div>
                        <div className="col-3 text-center">
                            <span className="text-white">May Dhruva</span>
                            <div className="div_social_icons">
                                <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://www.instagram.com/dhruvawmco/?hl=en")}>
                                    <BsIcons.BsInstagram />
                                </button>
                            </div>
                        </div>
                        <div className="col-3 text-center">
                            <span className="text-white">Wedding solution by Dhruva</span>
                            <div className="div_social_icons">
                                <button className="btn btn-light btn_social_icons rounded-circle border border-light m-2" onClick={() => OpenInNewTab("https://www.instagram.com/dhruvawmco/?hl=en")}>
                                    <BsIcons.BsInstagram />
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="w-100 mx-auto border border-top-light" />

                    <div className="row p-3">
                        <div className="col-4 text-center">
                            <span className="text-center text-white ">Copyright 2021. All Rights Reserved by Dhruva</span>
                        </div>
                        <div className="col-4 text-center">
                            <span className="text-center text-white ">Developed and marketing by: <span className="p_contact_link" onClick={() => OpenInNewTab("https://www.orangedigitalmedia.in/")}>Orange Digital Media</span></span>
                        </div>
                        <div className="col-4 text-center">
                            <span className="text-center text-white "><span className="p_contact_link">Privacy Policy</span> - <span className="p_contact_link">Disclaimer</span> </span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WebsiteFooter
