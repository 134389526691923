import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Responsive.css';

import WebsiteNavbar from './Components/WebsiteNavbar';
import Home from './Home';
import WebsiteFooter from './Components/WebsiteFooter';

function App() {
	return (
		<>
			<WebsiteNavbar />
			<Home />
			<WebsiteFooter />
		</>
	);
}

export default App;
