import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import banner1 from "./Media/banner/banner1.jpg";
import banner2 from "./Media/banner/banner2.jpg";
import banner3 from "./Media/banner/banner3.jpg";
import banner4 from "./Media/banner/banner4.jpg";
import logo from "./Media/logo.png";
import heading_design_left from "./Media/heading_design_left.png";
import heading_design_right from "./Media/heading_design_right.png";

import services_decor from "./Media/services/services_decor.jpg";
import services_creatives from "./Media/services/services_creatives.jpg";
import services_food from "./Media/services/services_food.jpg";
import services_hospitality from "./Media/services/services_hospitality.jpg";
import services_invites from "./Media/services/services_invites.jpg";
import services_makeup from "./Media/services/services_makeup.jpg";
import services_photography from "./Media/services/services_photography.jpg";
import services_styling from "./Media/services/services_styling.jpg";
import services_transport from "./Media/services/services_transport.jpg";
import services_wedding from "./Media/services/services_wedding.jpg";

import yash_and_radhika from "./Media/video_images/yash_and_radhika.jpg";
import shivanna_daughter from "./Media/video_images/shivanna_daughter.jpg";
import kumaraswamy_family from "./Media/video_images/kumaraswamy_family.jpg";
import zaheer_ahmed_daughter from "./Media/video_images/zaheer_ahmed_daughter.jpg";

import * as BsIcons from "react-icons/bs";

import photo_gallery_1 from "./Media/photo_gallery/photo_gallery_1.png";
import photo_gallery_2 from "./Media/photo_gallery/photo_gallery_2.png";
import photo_gallery_3 from "./Media/photo_gallery/photo_gallery_3.png";
import photo_gallery_4 from "./Media/photo_gallery/photo_gallery_4.png";
import photo_gallery_5 from "./Media/photo_gallery/photo_gallery_5.png";
import photo_gallery_6 from "./Media/photo_gallery/photo_gallery_6.png";
import photo_gallery_7 from "./Media/photo_gallery/photo_gallery_7.png";
import photo_gallery_8 from "./Media/photo_gallery/photo_gallery_8.png";
import photo_gallery_9 from "./Media/photo_gallery/photo_gallery_9.png";
import photo_gallery_10 from "./Media/photo_gallery/photo_gallery_10.png";
import photo_gallery_11 from "./Media/photo_gallery/photo_gallery_11.png";
import photo_gallery_12 from "./Media/photo_gallery/photo_gallery_12.png";
import photo_gallery_border from "./Media/photo_gallery/photo_gallery_border.png";

import vendor from "./Media/vendor.png";
import sponser from "./Media/sponser.jpg";
import fashion_model from "./Media/fashion_model.png";
import fashion_designer from "./Media/fashion_designer.png";
// import samuhaka_vivah from "./Media/samuhaka_vivah.png";

const Home = () => {
    useEffect(() => {
        const checkPosition = () => {
            if (window.matchMedia('(max-width: 580px)').matches) {
                setServicesSliderSettings(
                    {
                        className: "center",
                        infinite: true,
                        slidesToShow: 2,
                        speed: 750,
                        rows: 2,
                        slidesPerRow: 1,
                        dots: false,
                        autoplay: true,
                        autoplaySpeed: 3000,
                    }
                );
            } else {
                setServicesSliderSettings(
                    {
                        className: "center",
                        infinite: true,
                        slidesToShow: 3,
                        speed: 750,
                        rows: 2,
                        slidesPerRow: 1,
                        dots: false,
                        autoplay: true,
                        autoplaySpeed: 3000,
                    }
                );
            }
        }

        checkPosition();
    }, [])

    const bannerSlidersettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        }
    };

    const [servicesSliderSettings, setServicesSliderSettings] = useState(
        {
            className: "center",
            infinite: true,
            slidesToShow: 3,
            speed: 750,
            rows: 2,
            slidesPerRow: 1,
            dots: false,
            autoplay: true,
            autoplaySpeed: 3000,
        }
    );

    // const servicesSliderSettings = {
    //     className: "center",
    //     infinite: true,
    //     slidesToShow: 3,
    //     speed: 750,
    //     rows: 2,
    //     slidesPerRow: 1,
    //     dots: false,
    //     autoplay: true,
    //     autoplaySpeed: 3000,

    // };

    const OpenInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    const submitVendorRegistration = async (e) => {
        e.preventDefault();
        return
    }

    const submitFashionModelRegistration = async (e) => {
        e.preventDefault();
        return
    }

    const [fashionModelBrandValue, setFashionModelBrandValue] = useState(false);

    const changeProfile = (e) => {
        var selectedValue = e.target.value;
        console.log(selectedValue);
        if (selectedValue == "Agency") {
            setFashionModelBrandValue(true);
        } else {
            setFashionModelBrandValue(false);
        }
    }

    return (
        <>
            <section className="section_banner_slider" id="home">
                <Slider className="div_slider" {...bannerSlidersettings}>
                    <div className="div_banner_slick_parent">
                        <div className="div_text_on_banner_image">
                            <h1 className="text_on_banner_slider">OFFICIAL WEDDING EXPO BY</h1>
                            <img src={logo} alt="official wedding expo" className="logo_on_slider" />
                        </div>
                        <img src={banner1} className="img_banner imageForText" alt="" />
                    </div>
                    <div>
                        <img src={banner2} className="img_banner" alt="" />
                    </div>
                    <div>
                        <img src={banner3} className="img_banner" alt="" />
                    </div>
                    <div>
                        <img src={banner4} className="img_banner" alt="" />
                    </div>
                </Slider>
            </section>

            <section className="section_our_services" id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Our Services</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Slider {...servicesSliderSettings}>
                                <div>
                                    <img src={services_decor} alt="services_decor" className="img_services" />
                                    <p className="text-center p_tag_services"><b>DECOR</b></p>
                                </div>

                                <div>
                                    <img src={services_wedding} alt="services_wedding" className="img_services" />
                                    <p className="text-center p_tag_services"><b>WEDDING PLANNING</b></p>
                                </div>

                                <div>
                                    <img src={services_makeup} alt="services_makeup" className="img_services" />
                                    <p className="text-center p_tag_services"><b>MAKE UP</b></p>
                                </div>

                                <div>
                                    <img src={services_transport} alt="services_transport" className="img_services" />
                                    <p className="text-center p_tag_services"><b>TRANSPORT & LOGISTICS</b></p>
                                </div>

                                <div>
                                    <img src={services_invites} alt="services_invites" className="img_services" />
                                    <p className="text-center p_tag_services"><b>INVITES & RSVP</b></p>
                                </div>

                                <div>
                                    <img src={services_photography} alt="services_photography" className="img_services" />
                                    <p className="text-center p_tag_services"><b>PHOTOGRAPHY & FILMS</b></p>
                                </div>

                                <div>
                                    <img src={services_styling} alt="services_styling" className="img_services" />
                                    <p className="text-center p_tag_services"><b>STYLING & DESIGNERS</b></p>
                                </div>

                                <div>
                                    <img src={services_food} alt="services_food" className="img_services" />
                                    <p className="text-center p_tag_services"><b>FOOD & BEVERAGE</b></p>
                                </div>

                                <div>
                                    <img src={services_hospitality} alt="services_hospitality" className="img_services" />
                                    <p className="text-center p_tag_services"><b>HOSPITALITY</b></p>
                                </div>

                                <div>
                                    <img src={services_creatives} alt="services_creatives" className="img_services" />
                                    <p className="text-center p_tag_services"><b>CREATIVES & GIFTING</b></p>
                                </div>

                            </Slider>
                        </div>

                    </div>
                </div>
            </section>

            <section className="section_destination_weddings" id="destination_weddings">

            </section>

            <section className="section_video_gallery" id="video_gallery">
                <div className="container">
                    <div className="row">
                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Video Gallery</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 div_video_gallery_col" onClick={() => { OpenInNewTab("https://www.youtube.com/watch?v=hOPIYN7FM9I&feature=youtu.be"); }}>
                            <img src={yash_and_radhika} alt="yash_and_radhika" className="img_video_gallery" />
                            <p className="text-center p_tag_video_gallery"><b>CELEBRITY MEGA STARS YASH & RADHIKA PANDIT WEDDING</b></p>
                            <BsIcons.BsYoutube className="youtube_icon_center" />
                        </div>

                        <div className="col-md-6 div_video_gallery_col" onClick={() => { OpenInNewTab("https://www.youtube.com/watch?v=sPKtZp10z6I"); }}>
                            <img src={shivanna_daughter} alt="shivanna_daughter" className="img_video_gallery" />
                            <p className="text-center p_tag_video_gallery"><b>NIRUPAMA & DILEEP |MEGA STAR SHIVANNA DAUGHTER WEDDING</b></p>
                            <BsIcons.BsYoutube className="youtube_icon_center" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 div_video_gallery_col" onClick={() => { OpenInNewTab("https://www.youtube.com/watch?v=dvDEz-tHdMQ"); }}>
                            <img src={kumaraswamy_family} alt="kumaraswamy_family" className="img_video_gallery" />
                            <p className="text-center p_tag_video_gallery"><b>KARNATAKA CHIEF MINISTER HD KUMARASWAMY FAMILY</b></p>
                            <BsIcons.BsYoutube className="youtube_icon_center" />
                        </div>
                        <div className="col-md-6 div_video_gallery_col" onClick={() => { OpenInNewTab("https://www.youtube.com/watch?v=5Ff53iFjMZQ&feature=youtu.be"); }}>
                            <img src={zaheer_ahmed_daughter} alt="zaheer_ahmed_daughter" className="img_video_gallery" />
                            <p className="text-center p_tag_video_gallery"><b>MLA ZAHEER AHMED WEDDING</b></p>
                            <BsIcons.BsYoutube className="youtube_icon_center" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_photo_gallery" id="photo_gallery">
                <div className="container">
                    <div className="row">
                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Photo Gallery</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_1} alt="Bridal Lehenga" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Bridal Lehenga</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_2} alt="photo_gallery_2" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Mehnedi Designs</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_3} alt="photo_gallery_3" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Wedding Decor</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                        </div>

                        <div className="col-md-3">
                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_4} alt="photo_gallery_4" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Outfits</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_5} alt="photo_gallery_5" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Wedding Jewellery</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_6} alt="photo_gallery_6" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Wedding photoshots</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                        </div>
                        <div className="col-md-3">
                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_7} alt="photo_gallery_7" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Blouse Designs</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_8} alt="photo_gallery_8" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Wedding Makeup</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_9} alt="photo_gallery_9" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Pre Wedding Shots</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                        </div>
                        <div className="col-md-3">
                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_10} alt="photo_gallery_10" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Wedding Saree</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_11} alt="photo_gallery_11" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Wedding Hairstyles</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                            <div className="div_img_photo_gallery">
                                <img src={photo_gallery_12} alt="photo_gallery_12" className="img_photo_gallery" />
                                <p className="text-center p_tag_photo_gallery">Groom Wedding Dresses</p>
                                <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="section_vendor_registration">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Vendor Registration</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row" style={{ position: "relative" }}>
                        <div className="col-12 p-0">
                            <img src={vendor} alt="vendor" className="img_registration" />
                        </div>

                        <div className="div_form_card">
                            <div className="row">
                                <div className="col-md-8"></div>
                                <div className="col-md-4">
                                    <div className="card form_card">

                                        <div className="card-body">
                                            <form onSubmit={(e) => submitVendorRegistration(e)}>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="vendor_name">Name</label>
                                                    <input type="text" name="name" id="vendor_name" placeholder="Name" className="form-control" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="vendor_mobile">Mobile</label>
                                                    <input type="number" name="mobile" id="vendor_mobile" placeholder="Mobile" className="form-control" pattern="[6-9]{1}[0-9]{9}" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="vendor_email">Email</label>
                                                    <input type="email" name="email" id="vendor_email" placeholder="Email" className="form-control" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="select_stall">Select Stall</label>
                                                    <select name="stall" id="select_stall" className="form-select">
                                                        <option value="Jewellery" selected>Jewellery</option>
                                                        <option value="Clothing Store">Clothing Store</option>
                                                        <option value="Invitation Cards">Invitation Cards</option>
                                                        <option value="Gifting">Gifting</option>
                                                        <option value="Startup Gifting">Startup Gifting</option>
                                                        <option value="Miscellaneous">Miscellaneous</option>
                                                        <option value="Live Makeup">Live Makeup</option>
                                                        <option value="Post Wedding">Post Wedding</option>
                                                        <option value="Restaurants">Restaurants</option>
                                                        <option value="Builders">Builders</option>
                                                        <option value="Removation and Painting">Removation and Painting</option>
                                                        <option value="Mattress / 1 Gram Gold">Mattress / 1 Gram Gold</option>
                                                        <option value="Silver Store and Gifting">Silver Store and Gifting</option>
                                                        <option value="Artificial Silver">Artificial Silver</option>
                                                        <option value="Car Rentals">Car Rentals</option>
                                                        <option value="Photo and Video">Photo and Video</option>
                                                        <option value="Album Making">Album Making</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <button type="submit" className="btn btn_dhruva d-block mx-auto my-2" >Register</button>

                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="section_sponser_registration">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Sponser Registration</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row" style={{ position: "relative" }}>
                        <div className="col-12 p-0">
                            <img src={sponser} alt="vendor" className="img_registration" />
                        </div>

                        <div className="div_form_card">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card form_card">

                                        <div className="card-body">
                                            <form onSubmit={(e) => submitVendorRegistration(e)}>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="sponser_name">Name</label>
                                                    <input type="text" name="name" id="sponser_name" placeholder="Name" className="form-control" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="sponser_mobile">Mobile</label>
                                                    <input type="number" name="mobile" id="sponser_mobile" placeholder="Mobile" className="form-control" pattern="[6-9]{1}[0-9]{9}" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="sponser_email">Email</label>
                                                    <input type="email" name="email" id="sponser_email" placeholder="Email" className="form-control" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="select_sponser">Package</label>
                                                    <select name="select_sponser" id="select_sponser" className="form-select">
                                                        <option value="Silver Package">Silver Package</option>
                                                        <option value="Gold Package">Gold Package</option>
                                                        <option value="Platinum Package">Platinum Package</option>
                                                        <option value="Diamond Package">Diamond Package</option>
                                                    </select>

                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn_dhruva d-block mx-auto my-2" >Register</button>

                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="section_fashion_model_registration">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Fashion Show Registration</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row" style={{ position: "relative" }}>
                        <div className="col-12 p-0">
                            <img src={fashion_model} alt="fashion_model" className="img_registration" />
                        </div>

                        <div className="div_form_card">
                            <div className="row">
                                <div className="col-md-8"></div>
                                <div className="col-md-4">
                                    <div className="card form_card">

                                        <div className="card-body">
                                            <form onSubmit={(e) => submitFashionModelRegistration(e)}>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_model_name">Name</label>
                                                    <input type="text" name="name" id="fashion_model_name" placeholder="Name" className="form-control" required="required" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_model_mobile">Mobile</label>
                                                    <input type="number" name="mobile" id="fashion_model_mobile" placeholder="Mobile" className="form-control" pattern="[6-9]{1}[0-9]{9}" required="required" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_model_email">Email</label>
                                                    <input type="email" name="email" id="fashion_model_email" placeholder="Email" className="form-control" required="required" />
                                                </div>

                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_model_brand">Brand / Company Name</label>
                                                    <input type="text" name="brand" id="fashion_model_brand" placeholder="Brand Name" className="form-control" required="required" />
                                                </div>

                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_model_brand">Profile</label>
                                                    <select name="fashion_model_profile" id="fashion_model_profile" className="form-select" required="required" onChange={(e) => changeProfile(e)}>
                                                        <option value="Individual" selected>Individual</option>
                                                        <option value="Agency">Agency</option>
                                                    </select>
                                                </div>

                                                {(fashionModelBrandValue == true) ? (
                                                    <div className="form-group mt-2">
                                                        <label htmlFor="fashion_model_agency">Agency Name</label>
                                                        <input type="text" name="agency" id="fashion_model_agency" placeholder="Agency Name" className="form-control" />
                                                    </div>
                                                ) : ("")}

                                                <div>
                                                    <button type="submit" className="btn btn_dhruva d-block mx-auto my-2" >Register</button>

                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="section_fashion_designer_registration">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Fashion Designer Registration</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row" style={{ position: "relative" }}>
                        <div className="col-12 p-0">
                            <img src={fashion_designer} alt="fashion_designer" className="img_registration" />
                        </div>

                        <div className="div_form_card">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card form_card">

                                        <div className="card-body">
                                            <form onSubmit={(e) => submitFashionModelRegistration(e)}>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_designer_name">Name</label>
                                                    <input type="text" name="name" id="fashion_designer_name" placeholder="Name" className="form-control" required="required" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_designer_mobile">Mobile</label>
                                                    <input type="number" name="mobile" id="fashion_designer_mobile" placeholder="Mobile" className="form-control" pattern="[6-9]{1}[0-9]{9}" required="required" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_designer_email">Email</label>
                                                    <input type="email" name="email" id="fashion_designer_email" placeholder="Email" className="form-control" required="required" />
                                                </div>

                                                <div className="form-group mt-2">
                                                    <label htmlFor="fashion_designer_brand">Brand / Company Name</label>
                                                    <input type="text" name="brand" id="fashion_designer_brand" placeholder="Brand Name" className="form-control" required="required" />
                                                </div>

                                                <div>
                                                    <button type="submit" className="btn btn_dhruva d-block mx-auto my-2" >Register</button>

                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </section >
{/* 
            <section className="section_samuhaka_vivah_registration">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Fashion Designer Registration</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row" style={{ position: "relative" }}>
                        <div className="col-12 p-0">
                            <img src={samuhaka_vivah} alt="samuhaka_vivah" className="img_registration" />
                        </div>

                        <div className="div_form_card">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card form_card">

                                        <div className="card-body">
                                            <form onSubmit={(e) => submitFashionModelRegistration(e)}>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="samuhaka_vivah_name">Name</label>
                                                    <input type="text" name="name" id="samuhaka_vivah_name" placeholder="Name" className="form-control" required="required" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="samuhaka_vivah_mobile">Mobile</label>
                                                    <input type="number" name="mobile" id="samuhaka_vivah_mobile" placeholder="Mobile" className="form-control" pattern="[6-9]{1}[0-9]{9}" required="required" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <label htmlFor="samuhaka_vivah_email">Email</label>
                                                    <input type="email" name="email" id="samuhaka_vivah_email" placeholder="Email" className="form-control" required="required" />
                                                </div>

                                                <div className="form-group mt-2">
                                                    <label htmlFor="samuhaka_vivah_brand">Brand / Company Name</label>
                                                    <input type="text" name="brand" id="samuhaka_vivah_brand" placeholder="Brand Name" className="form-control" required="required" />
                                                </div>

                                                <div>
                                                    <button type="submit" className="btn btn_dhruva d-block mx-auto my-2" >Register</button>

                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </section > */}

            <section className="section_location" id="location">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 div_heading">
                            <ul className="nav justify-content-center">
                                <li className="nav-item my-auto">
                                    <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                </li>
                                <li className="nav-item my-auto">
                                    <h1 className="h_tag_heading">Venue</h1>
                                </li>
                                <li className="nav-item my-auto">
                                    <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 p-0">
                            <iframe title="venue" className="venue_location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.6168457234944!2d77.59153401482227!3d12.996340890839681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16447c8f44d5%3A0x9e5a5f22251abe34!2sTennis%20Pavilion!5e0!3m2!1sen!2sin!4v1634116776395!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Home
