import React, { useEffect, useState } from 'react'
import { Container, Modal, Nav, Navbar } from 'react-bootstrap'
import logo_with_border_design from "../Media/logo_with_border_design.png";
import logo_without_border_design from "../Media/logo_without_border_design.png";
import top_design from "../Media/top_design.png";
import logo from "../Media/logo.png";

const WebsiteNavbar = () => {
    useEffect(() => {
        var nav_item = document.getElementsByClassName("nav-item")
        for (let i = 0; i < nav_item.length; i++) {
            const element = nav_item[i];
            let element_width = element.clientWidth;
            console.log(element_width)
        }
    }, [])

    const [getInvitationModalShow, setGetInvitationModalShow] = useState(false);

    const handleGetInvitationModalClose = () => setGetInvitationModalShow(false);
    const handleGetInvitationModalShow = () => setGetInvitationModalShow(true);


    const sendGetInvitationData = async (e) => {
        // return;
        e.preventDefault();

        const data = {
            name: e.target.name,
            mobile: e.target.mobile,
            email: e.target.email
        }

    }

    const [toggleValue, setToggleValue] = useState(true);

    const toggleClick = () => {
        setToggleValue(!toggleValue);
        var element = document.getElementById("logo_without_border_design");
        if (toggleValue) {
            element.style.height = "50px";
            element.style.top = "0px";
        } else {
            element.style.height = "100px";
            element.style.top = "";
        }
    }

    const navItemClick = () => {
        document.getElementById("toggle_button").click();
    }


    return (
        <>
            {/* MODAL for get invitation */}

            <Modal show={getInvitationModalShow} onHide={() => handleGetInvitationModalClose()}>
                <form onSubmit={(e) => sendGetInvitationData(e)}>

                    <Modal.Header closeButton>
                        <img src={logo} alt="modal logo" className="modal_logo d-block mx-auto" />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <input type="text" className="form-control" name="name" placeholder="Enter Name" />
                        </div>
                        <div className="form-group my-3">
                            <input type="text" className="form-control" name="mobile" placeholder="Enter Number" pattern />
                        </div>
                        <div className="form-group">
                            <input type="email" className="form-control" name="email" placeholder="Enter Email" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" value="Close" className="btn btn-secondary" onClick={() => handleGetInvitationModalClose()} />
                        <input type="submit" value="Submit" className="btn btn-primary" onClick={() => handleGetInvitationModalClose()} />
                    </Modal.Footer>
                </form>
            </Modal>

            <button className="btn btn_sticky" onClick={() => handleGetInvitationModalShow()}>Get Invitation</button>

            <img src={top_design} alt="top design" className="top_design" />

            <section className="section_navbar mt-5">

                <Navbar expand="lg">
                    <Container>
                        <img src={logo_without_border_design} className="logo_without_border_design d-lg-none" alt="logo with border design" id="logo_without_border_design" />
                        <Navbar.Toggle aria-controls="basic-navbar-nav" id="toggle_button" onClick={() => toggleClick()} />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <ul className="navbar-nav mx-auto ">
                                <li onClick={() => navItemClick()} className="nav-item my-auto">
                                    <a className="nav-link" aria-current="page" href="#home"><b>Home</b></a>
                                </li>
                                <li onClick={() => navItemClick()} className="nav-item my-auto">
                                    <a className="nav-link" href="#services"><b>Services</b></a>
                                </li>

                                <li onClick={() => navItemClick()} className="nav-item my-auto">
                                    <a className="nav-link" href="#destination_weddings"><b>Destination Weddings</b></a>
                                </li>

                                {/* <div className="div_content"></div> */}
                                <img src={logo_with_border_design} className="mx-auto logo_with_border_design " alt="logo with border design" />

                                <li onClick={() => navItemClick()} className="nav-item my-auto">
                                    <a className="nav-link" href="#video_gallery"><b>Video Gallery</b></a>
                                </li>

                                <li onClick={() => navItemClick()} className="nav-item my-auto">
                                    <a className="nav-link" href="#photo_gallery"><b>Photo Gallery</b></a>
                                </li>
                                <li onClick={() => navItemClick()} className="nav-item my-auto">
                                    <a className="nav-link" href="#location"><b>Venue</b></a>
                                </li>

                            </ul>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>

            </section>

        </>
    )
}

export default WebsiteNavbar
